<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

export default {
  components: {
    Layout,
    PageHeader
  },
  page: {
    title: "Administradores",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      title: "Detalhe administradores",
      items: [
        {
          text: "Administradores",
          href: "/admin"
        },
        {
          text: "Detalhe",
          active: true
        }
      ],
      admin: null,
      img_url: null,
      expandImg: null,
      user: {name: '', email: ''},
      submitted: false,
    };
  },
  validations: {
    user: {
      name: { required },
      email: { required, email }
    }
  },
  created(){
    const user = JSON.parse(localStorage.getItem('user'));
    let config = {
      method: 'get',
      url: `${process.env.VUE_APP_API}api/v1/admin/${this.$route.params.id}`,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': `Bearer ${user.data.access_token}`
      }
    };

    axios(config)
    .then( (response) => {
      this.admin = response.data.data.admin
      this.user = {
        name: this.admin.user.name,
        email: this.admin.user.email
      }
    })
    .catch((error) => {
      console.log(error)
      this.$router.push('/pages/error-404')
    });

    this.img_url = process.env.VUE_APP_IMG
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const user = JSON.parse(localStorage.getItem('user'));
        let config = {
          method: 'PUT',
          url: `${process.env.VUE_APP_API}api/v1/admin/${this.$route.params.id}`,
          headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${user.data.access_token}`
          },
          data: {
            name: this.user.name,
            email: this.user.email,
          }
        };

        axios(config)
        .then( () => {
          this.submitted = false;
          this.$bvToast.toast('Administrador editado com sucesso', {
            title: 'Tudo certo!',
            variant: 'success',
            solid: true
          });
          
        })
        .catch( () => {
          this.submitted = false;
          this.$bvToast.toast('Tente novamente', {
            title: 'Ocorreu um erro',
            variant: 'danger',
            solid: true
          });
        });
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay :show="submitted" rounded="sm">
      <div class="row" v-if="admin">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="handleSubmit">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input
                    id="name"
                    v-model="user.name"
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    :class="{ 'is-invalid': submitted && $v.user.name.$error }"
                  />
                  <div
                    v-if="submitted && !$v.user.name.required"
                    class="invalid-feedback"
                  >O nome é obrigatório</div>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    id="email"
                    v-model="user.email"
                    type="email"
                    name="email"
                    class="form-control"
                    placeholder="Email"
                    :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                  />
                  <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                    <span v-if="!$v.user.email.required">Email é obrigatório</span>
                    <span v-if="!$v.user.email.email">Email inválido</span>
                  </div>
                </div>
                
                <div class="text-right">
                  <button type="submit" class="btn btn-success">Salvar</button>
                </div>
              </form>
            </div>
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end row -->

      <b-row v-else>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton></b-skeleton>
          <div class="m-2"></div>
          <b-skeleton height="60px"></b-skeleton>
          <div class="m-4"></div>
          <b-skeleton></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
        </b-col>
        <b-col cols="12" class="mt-3">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
      </b-row>
    </b-overlay>
  </Layout>
</template>